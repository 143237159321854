<template>
  <div
    class="__wallet_card notranslate"
    :class="[cardData.amount == 0 ? 'promo_active' : '']"
  >
    <div class="__non_promo_code" v-if="cardData.amount != 0">
      <div class="__title">
        <p class="__title_text">
          {{ cardData.title }}
        </p>
      </div>
      <div class="__amount">
        <h3 class="__amount_header_text notranslate" v-if="cardData.amount">
          {{ processAmount(cardData.amount) }}
        </h3>
      </div>
      <div class="__discription">
        <p class="discription__text">
          {{ cardData.description }}
        </p>
        <div class="discription__icons">
          <div class="__icon__discription">
            <button>
              <img
                height="12.5px"
                src="../../../assets/Tick Square.svg"
                alt=""
              />
            </button>
            <p class="__icon_text" v-if="cardData.expiry_period == 0">
              {{ $t("dashboard.wallet.no_expiry") }}
            </p>
            <p class="__icon_text" v-if="cardData.expiry_period == 1">
              {{ $t("dashboard.wallet.monthly_bundle") }}
            </p>
            <p class="__icon_text" v-if="cardData.expiry_period == 2">
              {{ $t("dashboard.wallet.quarterly_bundle") }}
            </p>
            <p class="__icon_text" v-if="cardData.expiry_period == 3">
              {{ $t("dashboard.wallet.half_year") }}
            </p>
          </div>
          <div class="__icon__discription" v-if="cardData.sms_per_amount">
            <button>
              <img
                height="12.5px"
                src="../../../assets/Tick Square.svg"
                alt=""
              />
            </button>
            <p class="__icon_text notranslate">{{ cardData.sms_per_amount }}</p>
          </div>
          <div class="__icon__discription" v-else>
            <button>
              <img
                height="12.5px"
                src="../../../assets/Tick Square.svg"
                alt=""
              />
            </button>
            <p class="__icon_text">
              {{ cardData.rate }}
              {{ checkRateType }}
            </p>
          </div>
          <div class="__icon__discription">
            <button>
              <img
                height="12.5px"
                src="../../../assets/Tick Square.svg"
                alt=""
              />
            </button>
            <p class="__icon_text">
              {{ processQuantity(cardData) }}
              {{ checkLoginType }}
            </p>
          </div>
        </div>
      </div>
      <div class="">
        <div class="choose_plan">
          <button
            :class="[noBallance ? 'bg_grey' : 'bg_orange']"
            class="__choose_plan_action"
            @click.prevent="showChoseModal(cardData)"
          >
            {{ $t("dashboard.wallet.choose_plan") }}
          </button>
        </div>
      </div>
      <!-- ! CONFIRM PURCHASE MODAL -->
      <div
        class="modal fade"
        id="confirmpayment"
        tabindex="-1"
        role="dialog"
        aria-labelledby="group"
        aria-hidden="true"
        :class="{
          'modal-bg':
            this.$route.path.includes('messages') &&
            this.$store.getters['uistate/selectedCard'].amount,
        }"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div
            class="modal-content d-flex align-items-center"
            v-if="proMoSuccess"
          >
            <successMessage :message="proMoSuccess" />
            <button @click="hideModal" class="__close__success__modal">
              {{ $t("misc.button.close") }}
            </button>
          </div>
          <div v-else class="modal-content">
            <div class="modal-header pt-4 pb-2">
              <h5
                class="modal-title __modal__header__text"
                id="confirmpaymentLabel"
              >
                {{ $t("dashboard.wallet.confirm_payment") }}
              </h5>
            </div>
            <div class="modal-body m-0 p-0">
              <div
                class="row justify-content-center"
                style="padding-right: 1em; padding-left: 1em"
              >
                <div class="col-md-12">
                  <p class="___message">
                    {{
                      $t("dashboard.wallet.confirm_purchase", {
                        amount: Number(
                          userSelectedData?.amount ||
                            $store.getters["uistate/selectedCard"].amount
                        )?.toLocaleString(
                          $store.getters["auth/currentUser"].country === "CI"
                            ? "CI"
                            : "GH",
                          {
                            style: "currency",
                            currency:
                              $store.getters["auth/currentUser"].country ===
                              "CI"
                                ? "CFA"
                                : "GHS",
                          }
                        ),
                      })
                    }}
                  </p>
                  <div
                    @click="showPromoMoContent"
                    v-if="!showPromoContent"
                    class="__have__promo d-flex align-items-center"
                  >
                    <button class="_have__promo_icon mr-2">
                      <img
                        src="../../../assets//Stroke 3.svg"
                        width="8px"
                        height="8px"
                        alt=""
                      />
                    </button>
                    <div class="__have_promo_text">
                      {{ $t("dashboard.wallet.have_promo") }}
                    </div>
                  </div>
                  <div class="__have__promo_code_form" v-if="showPromoContent">
                    <el-form
                      :model="inputFormData"
                      :rules="rules"
                      ref="applyForm"
                      @submit.prevent.native="checkEnter"
                    >
                      <div class="form_input_container">
                        <el-form-item
                          prop="promo_code"
                          :label="$t('dashboard.wallet.enter_promo')"
                        >
                          <el-input
                            type="text"
                            :placeholder="$t('dashboard.wallet.enter_promo')"
                            autocomplete="false"
                            v-model="inputFormData.promo_code"
                          ></el-input>
                        </el-form-item>
                      </div>
                    </el-form>
                  </div>
                  <Alert
                    v-if="proMoError"
                    :message="proMoError"
                    :type="'alert-danger'"
                  />
                </div>
              </div>
              <div class="wallet_footer__btns">
                <button class="cancel notranslate" @click="hideModal">
                  {{ $t("misc.button.cancel") }}
                </button>
                <el-button
                  v-if="!showPromoContent"
                  :loading="promoLoading"
                  type="primary"
                  style="width: 176px; height: 51px"
                  @click="confirmPurchase('no_promo')"
                  >{{ $t("dashboard.wallet.confirm_prompt") }}</el-button
                >
                <el-button
                  v-if="showPromoContent"
                  :loading="promoLoading"
                  type="primary"
                  style="width: 176px; height: 51px"
                  @click="applyPromoCode('applyForm')"
                  >{{ $t("dashboard.wallet.apply") }}</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="__pro__code" v-else>
      <div class="__title">
        <p class="__title_text __amount_header_text">
          {{ cardData.description }}
        </p>
        <!-- <div class="__amount">
                    <h3 class="__amount_header_text">
                        GHC {{cardData.amount}} <span class="__per__screen">{{cardData.sms_per_amount}}</span>
                    </h3>
                </div> -->
      </div>
      <div class="__discription description-promo">
        <p class="discription__text">
          {{
            checkLoginType == "SMS"
              ? $t("dashboard.wallet.optimal")
              : $t("dashboard.wallet.optimal_voice")
          }}
        </p>
        <p class="__sub_text validity-promo" v-if="cardData.expiry_period == 0">
          {{ $t("dashboard.wallet.no_expiry") }}
        </p>
        <p class="__sub_text validity-promo" v-if="cardData.expiry_period == 1">
          {{ $t("dashboard.wallet.monthly_bundle") }}
        </p>
        <p class="__sub_text validity-promo" v-if="cardData.expiry_period == 2">
          {{ $t("dashboard.wallet.quarterly_bundle") }}
        </p>
        <p class="__sub_text validity-promo" v-if="cardData.expiry_period == 3">
          {{ $t("dashboard.wallet.half_year") }}
        </p>
      </div>
      <div class="__promo_action">
        <p class="__promo_total" v-loading="amount_loading">
          {{ getPromoAmountComputed }} {{ checkLoginType }}
        </p>
        <el-form
          ref="ruleForm"
          :rules="promoRules"
          :model="promoForm"
          @submit.prevent.native="checkEnter"
        >
          <div class="d-flex justify-content-between">
            <el-form-item prop="promo_amount" style="height: 40px !important">
              <el-input
                id="__promo_input"
                type="text"
                :placeholder="$t('dashboard.wallet.enter_your_amount')"
                v-model="promoForm.promo_amount"
                @input="getPromoAmount(cardData.is_promo)"
              />
            </el-form-item>
            <el-button
              :loading="loading"
              style="height: 40px !important"
              @click="buyPromo('ruleForm', cardData.expiry_period)"
              id="__promo__button"
              type="primary"
            >
              {{ $t("dashboard.wallet.buy") }}
            </el-button>
          </div>
        </el-form>
      </div>
    </div>
    <el-dialog
      :title="$t('dashboard.wallet.confirm_prompt')"
      :visible.sync="confirmDialog"
      :width="dynamicWidth"
      style="z-index: 9999 !important"
      :append-to-body="true"
    >
      <span>
        {{
          $t("dashboard.wallet.confirm_purchase", {
            amount: Number(promoForm.promo_amount).toLocaleString("en-US", {
              style: "currency",
              currency:
                $store.getters["auth/currentUser"].country === "CI"
                  ? "CFA"
                  : "GHS",
            }),
          })
        }}
      </span>
      <div>
        <p>
          {{ $t("dashboard.wallet.for") }}
          <span style="color: #f89623"
            >{{ getPromoAmountComputed }} {{ checkLoginType }}</span
          >
        </p>
      </div>
      <div>
        <p>
          Validity:
          <span
            style="color: #f89623"
            class="__icon_text"
            v-if="cardData.expiry_period == 0"
          >
            {{ $t("dashboard.wallet.no_expiry") }}
          </span>
          <span
            style="color: #f89623"
            class="__icon_text"
            v-if="cardData.expiry_period == 1"
          >
            {{ $t("dashboard.wallet.monthly_bundle") }}
          </span>
          <span
            style="color: #f89623"
            class="__icon_text"
            v-if="cardData.expiry_period == 2"
          >
            {{ $t("dashboard.wallet.quarterly_bundle") }}
          </span>
          <span
            style="color: #f89623"
            class="__icon_text"
            v-if="cardData.expiry_period == 3"
          >
            {{ $t("dashboard.wallet.half_year") }}
          </span>
        </p>
      </div>
      <span slot="footer" class="dialog-footer notranslate">
        <el-button @click="confirmDialog = false">{{
          $t("misc.button.cancel")
        }}</el-button>
        <el-button
          type="danger"
          @click="confirmFromPromo"
          :loading="buttonLoading"
          >{{ $t("misc.button.confirm") }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import $ from "jquery";
  import store from "@/state/store";
  import Storage from "@/services/storage";
  import logintType from "@/helpers/loginType";
  import _ from "lodash";
  import moment from "moment/moment";
  const storage = new Storage();

  export default {
    name: "wallet-card",
    props: {
      cardData: {
        required: true,
      },
      userSelectedData: {
        required: true,
      },
    },

    data() {
      var validatePromoCod = (rule, value, callback) => {
        if (!this.promoForm.promo_amount) {
          callback(new Error(this.$t("dashboard.wallet.please_enter_amount")));
        } else if (this.promoForm.promo_amount < 10) {
          callback(new Error(this.$t("dashboard.wallet.less_than_10")));
        } else if (isNaN(this.promoForm.promo_amount)) {
          callback(new Error(this.$t("dashboard.wallet.valid_amount")));
        } else {
          callback();
        }
      };
      return {
        selectedCard: {
          amount: "",
          validity: "",
          total_sms: "",
          bundle_type: logintType() === "sms" ? 1 : 2,
        },
        loading: false,
        promoLoading: false,
        proMoSuccess: "",
        proMoError: "",
        showPromoContent: false,
        promoForm: {
          promo_amount: "0",
          type: "",
        },
        number_of_sms: 0,
        inputFormData: {
          promo_code: "",
        },
        // promo form rules
        promoRules: {
          promo_amount: [{ validator: validatePromoCod }],
        },
        rules: {
          promo_code: [
            { required: true, message: this.$t("misc.form.required") },
          ],
        },
        buttonLoading: false,
        confirmDialog: false,
        promo_amount_backend: 0,
        amount_loading: false,
      };
    },
    computed: {
      dynamicWidth() {
        if (screen.width < 768) {
          return "80%";
        } else {
          return "45%";
        }
      },
      isCoporate() {
        return true;
      },
      noBallance() {
        let ballance = store.getters["wallet/ballance"];
        if (ballance.wallet == null || ballance.wallet == 0) return true;
        return false;
      },
      getAmount() {
        return this.selectedCard;
      },
      userSelectedCard() {
        store.getters["uistate/selectedCard"];
      },
      getPromoAmountComputed() {
        if (logintType() == "sms") {
          return Number(this.promo_amount_backend).toLocaleString();
        }
        return this.convertLargeMinutesToFormat(this.promo_amount_backend);
      },
      checkLoginType() {
        return logintType() == "sms"
          ? "SMS"
          : this.$t("dashboard.wallet.minutes");
      },
      checkRateType() {
        const country = storage.getSavedState("currentUser").country;
        if (logintType() == "sms") {
          if (country == "CI" || country == "SN") {
            return `FCFA ${this.$t("dashboard.send_message.sms.per_sms")}`;
          } else {
            return `GHS ${this.$t("dashboard.send_message.sms.per_sms")}`;
          }
        } else {
          if (country == "CI" || country == "SN") {
            return `FCFA ${this.$t("dashboard.send_message.voice.per_minute")}`;
          } else {
            return `GHS ${this.$t("dashboard.send_message.voice.per_minute")}`;
          }
        }
      },
    },

    methods: {
      showChoseModal(data) {
        if (this.noBallance) {
          this.$notify({
            title: "error",
            message: this.$t("dashboard.wallet.insufficient_balance"),
            type: "warning",
          });
          return;
        }
        this.showPromoContent = false;
        let total_sms = Math.round(
          parseInt(data.amount) / parseFloat(data.rate)
        );
        let user_type_rate = 0;
        // user_type_rate = 2 means corporate
        // user_type_rate = 1 means individual
        // user_type_rate = 3 means both
        if (logintType() == "sms") {
          if (storage.getSavedState("isCorporate")) {
            user_type_rate = 2;
          } else {
            user_type_rate = 1;
          }
        } else {
          user_type_rate = 3;
        }
        let payload = {
          amount: data.amount,
          type: data.is_promo == 0 ? "standard" : "promo",
          promo_code: "",
          bundle_type: logintType() === "sms" ? 1 : 2,
          user_type_rate: user_type_rate,
        };
        if (logintType() === "sms") {
          payload.num_of_sms = total_sms;
        } else {
          payload.num_of_seconds = parseInt(total_sms) * 60;
        }
        this.$emit("choseBundle", payload);
        this.selectedCard = payload;
        store.dispatch("uistate/setSelectCard", payload);
        $("#confirmpayment").modal("show");
      },
      showPromoMoContent() {
        this.showPromoContent = true;
      },
      hideModal() {
        $("#confirmpayment").modal("hide");
        this.selectedPrice = "";
        this.showPromoContent = false;
        this.proMoError = null;
        this.proMoSuccess = null;
        this.promoLoading = false;
        this.selectedCard = {};
        store.dispatch("uistate/setSelectCard", {});
      },
      processAmount(amount) {
        const parsedAmount = Number(amount);
        // const language = store.getters["language/currentLanguage"];
        const country = storage.getSavedState("currentUser").country;
        const isFrancophoneCountry = country === "CI" || country === "SN";
        return parsedAmount.toLocaleString(
          isFrancophoneCountry ? "fr-CI" : "en-US",
          {
            style: "currency",
            currency: isFrancophoneCountry ? "CFA" : "GHS",
          }
        );
      },
      convertLargeMinutesToFormat(minutes) {
        // Handle potential NaN input
        if (isNaN(minutes)) {
          return "Invalid input";
        }

        // Convert to a number and round to 2 decimal places to avoid floating point issues
        let totalMinutes = Math.round(Number(minutes) * 100) / 100;

        let hours = Math.floor(totalMinutes / 60);
        let remainingMinutes = Math.floor(totalMinutes % 60);
        let seconds = Math.round((totalMinutes % 1) * 60);

        // Pad with leading zeros if necessary
        let formattedHours = hours.toString().padStart(2, "0");
        let formattedMinutes = remainingMinutes.toString().padStart(2, "0");
        let formattedSeconds = seconds.toString().padStart(2, "0");

        return `${formattedHours}h ${formattedMinutes}m ${formattedSeconds}s`;
      },
      processQuantity(cardData) {
        const quantity = Math.round(
          parseInt(cardData.amount) / parseFloat(cardData.rate).toFixed(8)
        );
        if (logintType() == "sms") {
          return quantity.toLocaleString();
        }

        return this.convertLargeMinutesToFormat(quantity);
      },
      confirmPurchase(type) {
        this.promoLoading = true;
        this.proMoSuccess = null;
        this.proMoError = null;
        const data = { ...store.getters["uistate/selectedCard"] };
        if (type == "promo") {
          data.promo_code = this.inputFormData.promo_code;
        }
        let user_type_rate = 0;
        // user_type_rate = 2 means corporate
        // user_type_rate = 1 means individual
        // user_type_rate = 3 means both
        if (logintType() == "sms") {
          if (storage.getSavedState("isCorporate")) {
            user_type_rate = 2;
          } else {
            user_type_rate = 1;
          }
        } else {
          user_type_rate = 3;
        }
        data.user_type_rate = user_type_rate;
        store
          .dispatch("wallet/purchaseBundle", data)
          .then((response) => {
            if (response) {
              this.proMoSuccess = response.data.message;
              this.promoLoading = false;
              store.dispatch("wallet/accountBalance");
              store.dispatch("wallet/walletBundleHistory");
              this.$mixpanel.track("Top up successful", {
                amount: data.amount,
                type: data.type,
                bundle_type: data.bundle_type,
                promo_code: data.promo_code,
              });
              // for franco
              if (this.$store.getters["auth/currentUser"].country === "CI") {
                this.$mixpanelFranco.track("Top up successful", {
                  amount: data.amount,
                  type: data.type,
                  bundle_type: data.bundle_type,
                  promo_code: data.promo_code,
                });
              }
            }
          })
          // expirey bundles
          .catch((err) => {
            this.promoLoading = false;
            if (err.response?.data == undefined) {
              this.proMoError = this.$t("misc.general_error");
              this.$mixpanel.track("Top up failed", {
                error: "An error occured try again.",
                amount: data.amount,
                type: data.type,
                bundle_type: data.bundle_type,
                promo_code: data.promo_code,
              });

              //  for franco
              if (this.$store.getters["auth/currentUser"].country === "CI") {
                this.$mixpanelFranco.track("Top up failed", {
                  error: "An error occured try again.",
                  amount: data.amount,
                  type: data.type,
                  bundle_type: data.bundle_type,
                  promo_code: data.promo_code,
                });
              }
            }
            if (err.response && err.response.status === 404) {
              this.proMoError = err.response.data.message;
              let data = err.response.data.data;
              const wasSelected = { ...store.getters["uistate/selectedCard"] };
              if (data.error == "Insufficient wallet balance") {
                this.proMoError = this.$t(
                  "dashboard.wallet.insufficient_wallet_balance"
                );
                this.$mixpanel.track("Top up failed", {
                  error: "Insufficient wallet balance",
                  amount: wasSelected.amount,
                  type: wasSelected.type,
                  bundle_type: wasSelected.bundle_type,
                  promo_code: wasSelected.promo_code,
                });

                //  for franco
                if (this.$store.getters["auth/currentUser"].country === "CI") {
                  this.$mixpanelFranco.track("Top up failed", {
                    error: "Insufficient wallet balance",
                    amount: wasSelected.amount,
                    type: wasSelected.type,
                    bundle_type: wasSelected.bundle_type,
                    promo_code: wasSelected.promo_code,
                  });
                }
              }
              if (data.amount) {
                this.proMoError = data.amount[0];
                this.$mixpanel.track("Top up failed", {
                  error: wasSelected.amount[0],
                  amount: wasSelected.amount,
                  type: wasSelected.type,
                  bundle_type: wasSelected.bundle_type,
                  promo_code: wasSelected.promo_code,
                });

                //  for franco
                if (this.$store.getters["auth/currentUser"].country === "CI") {
                  this.$mixpanelFranco.track("Top up failed", {
                    error: wasSelected.amount[0],
                    amount: wasSelected.amount,
                    type: wasSelected.type,
                    bundle_type: wasSelected.bundle_type,
                    promo_code: wasSelected.promo_code,
                  });
                }
              }
            }
            store.dispatch("wallet/accountBalance");
            store.dispatch("wallet/walletBundleHistory");
          })
          .finally(() => {
            this.promoLoading = false;
            store.dispatch("wallet/accountBalance");
            store.dispatch("wallet/walletBundleHistory");
          });
      },
      applyPromoCode(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.confirmPurchase("promo");
          } else {
            return false;
          }
        });
      },
      buyPromo(formName, type) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.promoForm.type = type == 0 ? "standard" : "promo";
            this.confirmDialog = true;
          } else {
            return false;
          }
        });
      },
      confirmFromPromo() {
        this.buttonLoading = true;
        this.proMoSuccess = null;
        this.proMoError = null;
        let user_type_rate = 0;
        // user_type_rate = 2 means corporate
        // user_type_rate = 1 means individual
        // user_type_rate = 3 means both
        if (logintType() == "sms") {
          if (storage.getSavedState("isCorporate")) {
            user_type_rate = 2;
          } else {
            user_type_rate = 1;
          }
        } else {
          user_type_rate = 3;
        }
        let payload = {
          amount: this.promoForm.promo_amount,
          type: this.promoForm.type,
          bundle_type: logintType() === "sms" ? 1 : 2,
          user_type_rate: user_type_rate,
        };
        if (logintType() === "sms") {
          ///  0.025
          payload.num_of_sms =
            parseInt(this.promoForm.promo_amount) / this.promo_amount_backend;
        } else {
          payload.num_of_seconds =
            (parseInt(this.promoForm.promo_amount) /
              this.promo_amount_backend) *
            60;
        }
        store
          .dispatch("wallet/purchaseBundle", payload)
          .then((response) => {
            if (response) {
              this.loading = false;
              this.$notify({
                title: this.$t("misc.toast.success"),
                message: response.data.message,
                type: "success",
              });
              this.confirmDialog = false;
              // Get account balance
              store.dispatch("wallet/accountBalance");
              store.dispatch("wallet/walletBundleHistory");
              this.confirmDialog = false;
              this.$mixpanel.track("Top up successful", {
                bundle_type: logintType() === "sms" ? "SMS" : "Voice",
                amount: this.promoForm.promo_amount,
                type: this.promoForm.type == "promo" ? "30 days" : "No Expiry",
              });
              // for franco
              if (this.$store.getters["auth/currentUser"].country === "CI") {
                this.$mixpanelFranco.track("Top up successful", {
                  bundle_type: logintType() === "sms" ? "SMS" : "Voice",
                  amount: this.promoForm.promo_amount,
                  type:
                    this.promoForm.type == "promo" ? "30 days" : "No Expiry",
                });
              }

              // setTimeout( () => {
              //     location.reload()
              // },100)
            }
          })
          .catch((err) => {
            this.loading = false;
            if (err.response?.data == undefined) {
              this.$notify({
                title: this.$t("misc.toast.error"),
                message: this.$t("misc.general_error"),
                type: "error",
              });
              this.$mixpanel.track("Top up failed", {
                error: "An error occured try again.",
                amount: payload.amount,
                type: payload.type,
                bundle_type: payload.bundle_type == 1 ? "SMS" : "Voice",
              });
              //  for franco
              if (this.$store.getters["auth/currentUser"].country === "CI") {
                this.$mixpanelFranco.track("Top up failed", {
                  error: "An error occured try again.",
                  amount: payload.amount,
                  type: payload.type,
                  bundle_type: payload.bundle_type == 1 ? "SMS" : "Voice",
                });
              }
            }

            if (err.response && err.response.status === 404) {
              let data = err.response.data.data;
              if (data.error) {
                this.$notify({
                  title: this.$t("misc.toast.error"),
                  message: data.error,
                  type: "error",
                });
                this.$mixpanel.track("Top up failed", {
                  error: data.error,
                  amount: payload.amount,
                  type: payload.type,
                  bundle_type: payload.bundle_type == 1 ? "SMS" : "Voice",
                });
                // for franco
                if (this.$store.getters["auth/currentUser"].country === "CI") {
                  this.$mixpanelFranco.track("Top up failed", {
                    error: data.error,
                    amount: payload.amount,
                    type: payload.type,
                    bundle_type: payload.bundle_type == 1 ? "SMS" : "Voice",
                  });
                }
              }
              if (data.amount) {
                this.$notify({
                  title: "Error",
                  message: data.amount[0],
                  type: "error",
                });
                this.$mixpanel.track("Top up failed", {
                  error: data.amount[0],
                  amount: payload.amount,
                  type: payload.type,
                  bundle_type: payload.bundle_type == 1 ? "SMS" : "Voice",
                });

                //  for franco
                if (this.$store.getters["auth/currentUser"].country === "CI") {
                  this.$mixpanelFranco.track("Top up failed", {
                    error: data.amount[0],
                    amount: payload.amount,
                    type: payload.type,
                    bundle_type: payload.bundle_type == 1 ? "SMS" : "Voice",
                  });
                }
              }
              // Get account balance
              store.dispatch("wallet/accountBalance");
              store.dispatch("wallet/walletBundleHistory");
            }
          })
          .finally(() => {
            this.buttonLoading = false;

            // Get account balance
            store.dispatch("wallet/accountBalance");
            store.dispatch("wallet/walletBundleHistory");
          });
      },
      getPromoAmount: _.debounce(function (isExpriyNonExpiry) {
        this.amount_loading = true;
        let user_type_rate = 0;
        // user_type_rate = 2 means corporate
        // user_type_rate = 1 means individual
        // user_type_rate = 3 means both
        if (logintType() == "sms") {
          if (storage.getSavedState("isCorporate")) {
            user_type_rate = 2;
          } else {
            user_type_rate = 1;
          }
        } else {
          user_type_rate = 3;
        }
        let payload = {
          amount: this.promoForm.promo_amount,
          type: logintType() === "sms" ? 1 : 2,
          mode: isExpriyNonExpiry == 1 ? 1 : 0,
          user_type_rate: user_type_rate,
          country: storage.getSavedState("currentUser").country,
        };
        if (
          this.promoForm.promo_amount == "" ||
          this.promoForm.promo_amount < 10 ||
          isNaN(this.promoForm.promo_amount)
        ) {
          this.amount_loading = false;
          return;
        }
        store
          .dispatch("wallet/getPromoAmount", payload)
          .then((response) => {
            this.promo_amount_backend = response.data.quantity;
          })
          .finally(() => {
            this.amount_loading = false;
          });
        return this.promo_amount_backend;
      }, 500),
    },
  };
</script>

<style lang="scss" scoped>
  @import url("../../../styles/wallet/__wallet_card.scss");

  #confirmpayment {
    z-index: 9999 !important;
  }

  .modal-bg {
    background-color: #0f0f0fbd !important;
  }
</style>

<style>
  #confirmpayment >>> .v-modal {
    z-index: 2 !important;
  }
</style>
