<template>
  <div class="bundle-dialog-wrapper">
    <el-dialog
      :visible.sync="dialogVisible"
      top="2vh"
      lock-scroll
      :modal-append-to-body="false"
      :modal="showModal"
      style="z-index: 1020 !important"
      custom-class="modal-backdrop-dark"
    >
      <div slot="title">
        <div class="__wallet__header">
          <div class="__sms_">
            <h3 class="__header__">
              {{ sms_voice }}
            </h3>
            <p class="__text notranslate">
              {{ $t("dashboard.wallet.subtitle") }}
              <!-- Please select a bundle offer -->
            </p>
          </div>
        </div>
      </div>
      <retail :userTopup="amountTopup"></retail>
    </el-dialog>
  </div>
</template>

<script>
  import retail from "./retail.vue";
  import logintType from "../../../helpers/loginType";
  export default {
    name: "bundleOfferDialog",
    data() {
      return {};
    },
    components: {
      retail,
    },
    props: {
      amountTopup: {
        type: Number,
        default: 0,
      },
      value: {
        type: Boolean,
        default: false,
        required: false,
      },
      showModal: {
        type: Boolean,
        default: true,
        required: false,
      },
    },
    computed: {
      dialogVisible: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit("input", val);
          if (!val) {
            this.$emit("modal-closed");
          }
        },
      },
      sms_voice() {
        return logintType() == "sms"
          ? this.$t("dashboard.wallet.sms_bundles")
          : this.$t("dashboard.wallet.voice_bundles");
      },
    },
  };
</script>

<style>
  .bundle-dialog-wrapper .el-dialog__wrapper {
    z-index: 1000 !important;
  }

  .bundle-dialog-wrapper .v-modal {
    z-index: 999 !important;
  }
</style>
